<template>
  <div class="detail">
    <div class="case">
      <div class="title">
        <div class="img">Q1</div>
        {{ list.title }}
      </div>
      <div class="video" v-if="list.videoFile">
        <video
          id="myVideo"
          :src="list.videoFile"
          @error="videoErrorCallback"
          controls
        ></video>
      </div>
      <div class="text" v-if="list.informationContent">
        <div class="rich" v-html="list.informationContent">
          {{ list.informationContent }}
        </div>
      </div>
    </div>
    <div
      class="btn"
      v-if="list.attachments"
      @click="uploadWJ(list.attachments)"
    >
      <img
        class="wenjianjia"
        :src="`${publickPath}static/img/photo/wenjianjia.png`"
      />下载文件
    </div>
  </div>
</template>

<script>
import api from '@/api';
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      id: this.$route.query.id,
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    // 获取页面内容
    getList() {
      api
        .getAnnouncementMore({ id: this.id })
        .then((res) => {
          if (res.code == 'SUCCESS') {
            this.list = res.data;
          }
        })
        .catch(() => {});
    },
    // 点击下载
    uploadWJ(v) {
      window.open(v);
    },
    // 播放报错
    videoErrorCallback() {
      console.log('播放视频出错');
    },
  },
};
</script>

<style>
page {
  background-color: #fbfcff;
}
</style>
<style lang="less" scoped>
.detail {
  padding: 0.625rem 0.9375rem;
  .home {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 1rem;
      height: 0.875rem;
    }
  }
  .case {
    padding: 0 0.9375rem;
    background: #ffffff;
    box-shadow: 0 0.3125rem 0.2813rem 0 rgba(188, 188, 188, 0.1);
    border-radius: 0.625rem;

    .title {
      position: relative;
      padding-left: 1.9375rem;
      height: 3.75rem;
      line-height: 3.75rem;
      border-bottom: 0.0313rem solid #f6f6fa;
      font-size: 0.9375rem;
      font-weight: 700;

      .img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 0.1875rem;
        background: url(/static/img/photo/dialogue.png) no-repeat;
        background-size: 1.5625rem 1.0625rem;
        background-position: 0 52%;
        color: #fff;
        font-size: 0.625rem;
        box-sizing: border-box;
      }
    }

    .video {
      overflow: hidden;
      margin-top: 0.625rem;
      border-radius: 0.3125rem 0.3125rem 0 0;

      video {
        width: 100%;
      }
    }

    .text {
      width: 100%;
      margin-top: 0.9375rem;
      font-size: 0.9375rem;
      font-weight: 400;
      color: #333333;
      padding-bottom: 1.5625rem;
    }

    .rich {
      line-height: 1.7188rem;
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-line;
    }
  }
  .btn {
    height: 2.8125rem;
    background: #336df3;
    border-radius: 0.4063rem;
    text-align: center;
    line-height: 2.8125rem;
    font-size: 1.125rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    .wenjianjia {
      margin-right: 0.8125rem;
      width: 1.375rem;
      height: 1.0625rem;
      vertical-align: middle;
    }
  }
}
</style>
